<template>
  <div>
    <router-view v-show='this.$route.matched.length==3'></router-view>
    <PageHeaderLayout v-show='this.$route.matched.length==2'>
      <div class='main-page-content'>
        <!--        <el-row class='table-header'>-->
        <!--          <el-col :span='4'>-->
        <!--            <el-tooltip effect='dark' content='添加用户' placement='top-start'>-->
        <!--              <el-button type='primary' size='mini' icon='iconfont icon-tianjiacaidan2'-->
        <!--                         v-if="userPermissions.indexOf('user_create') != -1 && buttonType=='icon'"-->
        <!--                         @click='addButton(0)'></el-button>-->
        <!--            </el-tooltip>-->
        <!--            <el-button type='primary' size='mini'-->
        <!--                       v-if="userPermissions.indexOf('user_create') != -1 && buttonType=='text'" @click='addButton(0)'>-->
        <!--              添加-->
        <!--            </el-button>-->
        <!--          </el-col>-->

        <!--        </el-row>-->
        <div class='table-search'>
          <el-form :inline='true' size='mini' :model='searchCondition'>
            <el-form-item>
              <el-input v-model='searchCondition.keyword' placeholder='关键词搜索：姓名/账号/昵称/手机号/工号'
                        style='width: 240px'
                        clearable></el-input>
            </el-form-item>
            <el-form-item label=''>
              <el-select v-model='searchCondition.user_status' style='width: 100px!important;' placeholder='启用状态'
                         @change='searchButton' clearable>
                <el-option value='' label='全部'></el-option>
                <el-option :value='item.value' :label='item.label' v-for='(item) in userStatusOptions'
                           :key='item.value'></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label=''>
              <el-select placeholder='在职情况' v-model='searchCondition.is_leave' style='width: 100px' clearable>
                <el-option label='在职' value='N'></el-option>
                <el-option label='离职' value='Y'></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label=''>
              <el-select v-model='searchCondition.role_id' style='width: 120px' placeholder='选择角色'
                         @change='searchButton' clearable>
                <el-option :value='role.id' :label='role.display_name' v-for='(role) in rolesList'
                           :key='role.id'></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-select v-model='currentField' placeholder='精确匹配项' style='width: 140px' clearable>
                <el-option :value='item.value' :label='item.label' v-for='(item) in condFieldOptions'
                           :key='item.value'></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label=''>
              <el-input v-model='searchCondition[currentField]' :placeholder='`精确匹配内容`' clearable></el-input>
            </el-form-item>
            <!--            <el-form-item label=''>-->
            <!--              <el-select multiple v-model='searchCondition.status_bind' placeholder='钉钉状态' style='width: 120px'-->
            <!--                         clearable>-->
            <!--                <el-option value='Y' label='已绑'></el-option>-->
            <!--                <el-option value='N' label='未绑'></el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' @click='searchButton'>搜索</el-button>
              <el-button type='text' :icon='!showMore?"el-icon-caret-bottom":"el-icon-caret-top"'
                         @click='showMore=!showMore'>
                {{ !showMore ? '展开' : '隐藏' }}
              </el-button>

            </el-form-item>
          </el-form>
          <div v-show='showMore' style='display: flex;flex-direction: row;gap: 5px'>
            <CompanyCascader @handleSelect='handleDeptSelect'></CompanyCascader>
            <el-select multiple v-model='searchCondition.status_bind' placeholder='钉钉状态'
                       @change='searchButton'
                       style='width: 140px'
                       collapse-tags
                       clearable>
              <el-option value='Y' label='已绑'></el-option>
              <el-option value='N' label='未绑'></el-option>
            </el-select>
          </div>
        </div>
        <!--        操作栏-->
        <div style='display: flex;flex-direction: row;justify-content: space-between;margin-bottom: 8px;'>
          <div style='display: flex;flex-direction: row;gap: 5px'>
            <el-button type='primary' icon='el-icon-plus' @click='addButton(0)'>新增</el-button>
            <export-btn ref='refExportLink' type='default'
                        :lint-title='`导出表格`'
                        :can-export="userPermissions.indexOf('user_export')>-1"
                        @export='exportExcel'></export-btn>
          </div>
          <div style='display: flex;flex-direction: row;justify-content: right;gap: 2px'>
            <el-button class='page-btn' round type='primary' size='mini' @click="jumpToPage('purview')"
                       icon='el-icon-tickets' plain
                       v-if="userPermissions.indexOf('user_purview') != -1">用户权限
            </el-button>
            <el-button class='page-btn' round type='primary' size='mini' @click="jumpToPage('LoginLog')"
                       icon='el-icon-tickets'
                       v-if="userPermissions.indexOf('user_login_record') != -1">登录日志
            </el-button>
            <el-button class='page-btn' round type='primary' size='mini' @click="jumpToPage('UserOnline')"
                       icon='el-icon-user'>
              在线用户
            </el-button>
            <el-button class='page-btn' round type='info' size='mini' @click="jumpToPage('UserDepart')"
                       icon='el-icon-user'
                       v-if="userPermissions.indexOf('user_leave_list') != -1">离职人员
            </el-button>
            <el-button class='page-btn' round size='mini' @click="jumpToPage('dingUserInfo')" icon='el-icon-user'
                       v-if="userPermissions.indexOf('user_dingTalk') != -1">钉钉成员
            </el-button>
            <el-button class='page-btn' round type='success' size='mini' @click="jumpToPage('userWechat')"
                       icon='el-icon-user'
                       title='招商小程序'
                       v-if="userPermissions.indexOf('user_wechat') != -1">微信用户
            </el-button>
            <el-button class='page-btn' round type='warning' size='mini' @click="jumpToPage('memberList')"
                       icon='el-icon-user-solid'
                       title='组织成员在职离职转组一览' v-if="userPermissions.indexOf('user_member') != -1">成员异动一览
            </el-button>
          </div>
        </div>
        <div class='default-table' v-loading='loadingStaus'
             element-loading-text='加载中……'
             element-loading-spinner='el-icon-loading'>
          <MlPageTable :show-slot='!loadingStaus'
                       :all-columns='userColumns'
                       :table-name='tableName'
                       :data-list='userList'
                       :limit.sync='pagingData.page_size'
                       :page.sync='pagingData.current_page'
                       :total.sync='pagingData.total'
                       :show-index='true'
                       height='680px'
                       highlight-current-row
                       :cell-class-name='addCellClass'
                       @row-dblclick='dblclickRow'
                       @sort-change='sortChange'
                       @getList='getUserList'
                       border>

            <el-table-column label='启用停用' slot='first-column' prop='user_status' sortable='custom' width='100'
                             align='center'>
              <template slot-scope='scope'>
                <el-switch
                    v-model='scope.row.user_status'
                    :active-value='1'
                    :inactive-value='0'
                    @change='handleStatusChange(scope.row)'
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label='钉钉绑定' width='110' align='center'>
              <template slot-scope='{row}'>
                <el-link @click='showBindDialog(row)'>
                  <el-tag v-if='row.userid' type='success' effect='plain'><span class='el-icon-circle-check'></span>&nbsp;已绑定
                  </el-tag>
                  <el-tag v-else type='danger' effect='plain'><span class='el-icon-circle-close'></span>&nbsp;未绑定
                  </el-tag>
                </el-link>
              </template>
            </el-table-column>

            <el-table-column slot='handle-column' align='center' fixed='right' label='操作' width='120'>
              <template slot-scope='{row}'>
                <el-button type='text' size='mini' v-if="userPermissions.indexOf('user_edit') != -1"
                           @click='editButton(row.id,"edit")' icon='el-icon-edit'>编辑
                </el-button>
                &nbsp;
                <el-dropdown>
                  <span class='el-dropdown-link'>
                    更多<i class='el-icon-arrow-down el-icon--right'></i>
                  </span>
                  <el-dropdown-menu slot='dropdown'>
                    <el-dropdown-item>
                      <el-button type='text' size='mini' v-if="userPermissions.indexOf('user_group') !== -1"
                                 @click='userGroupBinding(row)' icon='el-icon-s-fold'>组别
                      </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item divided>
                      <el-button v-if="userPermissions.indexOf('user_pwd') !== -1" icon='el-icon-key' size='mini'
                                 type='text' @click='editButton(row.id,"password")'>密码
                      </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item divided>
                      <el-button type='text' size='mini' v-if="userPermissions.indexOf('user_edit_role') !== -1"
                                 @click='editButton(row.id,"role")' icon='el-icon-edit'>角色
                      </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item divided>
                      <el-button v-if="userPermissions.indexOf('user_delete') !== -1" icon='el-icon-delete'
                                 size='mini' type='text'
                                 @click='deleteButton(row.id)'>删除
                      </el-button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </MlPageTable>
        </div>
      </div>
    </PageHeaderLayout>

    <!--    用户信息-->
    <ApeDrawer :drawerData='drawerData' @drawerClose='drawerClose' @drawerConfirm='drawerConfirm'>
      <template slot='ape-drawer'>
        <div v-if='formData.userid' style='margin: 10px'>
          <el-tag type='success' effect='plain'><span class='el-icon-circle-check'></span>&nbsp;钉钉已绑定</el-tag>
        </div>
        <el-form :model='formData' :rules='rules' ref='userForm' label-position='right' label-width='96px'>
          <el-row>
            <el-col :span='22'>
              <el-form-item label='账号' prop='username'>
                <el-input v-model='formData.username' placeholder='账号，建议采用姓名首字母加工号，保证唯一'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='22'>
              <el-form-item label='昵称' prop='nickname'>
                <el-input v-model='formData.nickname' placeholder='昵称'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='22'>
              <el-form-item label='姓名' prop='name'>
                <el-input v-model='formData.name' placeholder='用户真实姓名'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='22'>
              <el-form-item label='手机号' prop='mobile'>
                <el-input v-model='formData.mobile' placeholder='手机号'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='22'>
              <el-form-item label='Email' prop='email'>
                <el-input v-model='formData.email'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='22'>
              <el-form-item label='工号' prop='job_number'>
                <el-input v-model='formData.job_number'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if='!this.formData.id'>
            <el-col :span='22'>
              <el-form-item label='密码' prop='password'>
                <el-input v-model='formData.password'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if='!this.formData.id'>
            <el-col :span='22'>
              <el-form-item label='角色绑定' prop='user_roles'>
                <el-select v-model='formData.user_roles' multiple filterable clearable placeholder='请选择'
                           style='width: 100%'>
                  <el-option
                      v-for='item in rolesList'
                      :key='item.id'
                      :label='item.display_name'
                      :value='item.id'>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!--          <el-row>-->
          <!--            <el-col>{{formData.user_roles}}</el-col>-->
          <!--          </el-row>-->
        </el-form>
      </template>
    </ApeDrawer>
    <!--    分组信息-->
    <ApeDrawer :drawerData='drawerGroupData' @drawerClose='drawerGroupClose' @drawerConfirm='drawerGroupConfirm'>
      <template slot='ape-drawer'>
        <div class='drawer-roles-premissions'>
          <el-checkbox :indeterminate='isIndeterminate' v-model='checkAll' @change='checkedIndeterminate'>
            全选({{ userGroups.length }}/{{ groupList.length }})
          </el-checkbox>
          <div style='margin: 15px 0;'></div>
          <el-checkbox-group v-model='userGroups' @change='handleCheckedCitiesChange' size='small'
                             style='text-align: left'>
            <el-checkbox v-for='(group,index) in groupList' :label='group.name' :key='group.name' border
                         style='margin-bottom: 5px;width: 180px'>
              {{ index + 1 }}）{{ group.display_name }}({{ group.artists_count || 0 }})
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </template>
    </ApeDrawer>
    <!--    用户登录日志-->
    <ApeDrawer :drawerData='drawerLoginData' @drawerClose='drawerLoginRecordClose'>
      <template slot='ape-drawer'>
        <LoginRecord :user-id='currentUserId' ref='userLoginRecord'></LoginRecord>
      </template>
    </ApeDrawer>
    <el-dialog title='钉钉账号关联' :visible.sync='dialogVisible' width='400'>
      <el-form label-suffix='：' :model='formData' label-width='100px'>
        <el-form-item label='用户'>{{ formData.nickname || '' }}</el-form-item>
        <el-form-item label='手机'>{{ formData.mobile || '' }}</el-form-item>
        <el-form-item label='钉钉ID'>{{ formData.userid || '' }}</el-form-item>
        <el-form-item label='钉钉ID'>
          <el-input v-model='bindForm.userid' placeholder='钉钉Userid' clearable></el-input>
        </el-form-item>
        <el-form-item label='修改原因'>
          <el-input v-model='bindForm.reason' placeholder='修改钉钉ID的原因' clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot='footer' class='dialog-footer'>
        <el-button @click='dialogVisible = false'>取 消</el-button>
        <el-button type='primary' @click='saveBindDing'>确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title='重置密码提示' :visible.sync='passWordDialog' width='400'>
      <el-form label-suffix=':' :model='formData' label-width='80px'>
        <el-form-item label='用户'>{{ formData.nickname || '' }}</el-form-item>
        <el-form-item label='手机'>{{ formData.mobile || '' }}</el-form-item>
        <el-form-item label='密码' prop='password'>
          <el-input v-model='formData.password' style='width: 160px'></el-input>
          <el-link style='margin-left: 10px' type='primary' @click='generateRandomPassword'>随机密码</el-link>
        </el-form-item>
      </el-form>
      <span slot='footer' class='dialog-footer'>
        <el-button @click='copyBtn'>保存并复制</el-button>
        <!--       </span>-->
        <!--        <el-button type="primary">保存</el-button>-->
     </span>
    </el-dialog>
    <el-dialog title='用户角色修改' :visible.sync='roleDialog' width='800'>
      <el-form label-suffix=':' :model='formData' label-width='80px'>
        <el-form-item label='用户'>{{ formData.nickname || '' }}</el-form-item>
        <!--        <el-form-item label='手机'>{{ formData.mobile || '' }}</el-form-item>-->
        <el-form-item label='角色'>
          <el-checkbox-group v-model='formData.user_roles'>
            <el-checkbox v-for='item in rolesList' :key='item.id' :label='item.id'>
              {{ item.display_name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot='footer' class='dialog-footer'>
               <el-button type='primary' @click='saveRole'>确定</el-button>
               <el-button @click='roleDialog = false'>取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import { mapGetters } from 'vuex'
import LoginRecord from '@/pages/user/LoginRecord'
import UserNameTag from '@/pages/user/components/UserNameTag'
import MSetTable from '@/components/common/MSetTable'
import MColumnSetting from '@/components/common/MColumnSetting'
import ExportBtn from '@/components/export/ExportBtn'
import MlPageTable from '@/components/common/MlPageTable'
import CompanyCascader from '@/components/dept/CompanyCascader'

export default {
  name: 'UserList',
  components: {
    CompanyCascader,
    MlPageTable,
    UserNameTag,
    PageHeaderLayout,
    ApeTable,
    LoginRecord,
    ApeDrawer,
    MSetTable,
    MColumnSetting,
    ExportBtn
  },
  data() {
    return {
      dialogVisible: false,
      bindForm: { id: null, userid: null, reason: null },
      showMore: false,
      // 搜索条件
      searchCondition: {
        keyword: null,
        dpt_id: null,
        username: null,
        nickname: null,
        name: null,
        mobile: null,
        job_number: null,
        user_status: 1,
        role_id: null,
        is_leave: 'N',
        status_bind: []
      },
      userStatusOptions: [
        { value: 1, label: '正常' },
        { value: 2, label: '禁用' },
        { value: 0, label: '停用' }
      ],
      condFieldOptions: [
        { value: 'name', label: '姓名' },
        { value: 'username', label: '账号' },
        { value: 'nickname', label: '昵称' },
        { value: 'mobile', label: '手机号' },
        { value: 'job_number', label: '工号' }
      ],
      currentField: null,
      loadingStaus: true,
      columns: [],
      userColumns: [
        { hide: true, prop: 'avatar', label: '头像', width: 60, sortable: false, fixed: true, type: 'image' },
        { hide: false, prop: 'nickname', label: '昵称', width: 100, sortable: false, fixed: false },

        { hide: false, prop: 'username', label: '账号', width: 100, sortable: true, fixed: false },
        { hide: false, prop: 'name', label: '姓名', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'job_number', label: '工号', width: 100, sortable: true, fixed: false },
        { hide: false, prop: 'mobile', label: '手机号', width: 100, sortable: true, fixed: false },
        { hide: false, prop: 'role_names', label: '角色', width: 220, sortable: false, fixed: false },
        { hide: false, prop: 'dept_names', label: '部门', width: 220, sortable: false, fixed: false },
        { hide: false, prop: 'position', label: '岗位', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'hired_at', label: '入职时间', width: 100, sortable: true, fixed: false },
        { hide: false, prop: '_is_leave', label: '在职情况', width: 100, sortable: true, fixed: false },
        { hide: true, prop: 'leave_date', label: '离职时间', width: 100, sortable: true, fixed: false },
        { hide: false, prop: 'login_at', label: '最近登录时间', width: 140, sortable: true, fixed: false },
        { hide: false, prop: 'last_login_ip', label: '最近登录IP', width: 140, sortable: true, fixed: false },
        {
          hide: false,
          prop: '_user_status',
          label: '状态',
          width: 80,
          sortable: true,
          fixed: false
        },
        {
          hide: true,
          prop: 'userid',
          label: '钉钉ID',
          slotName: 'userid',
          slot: true,
          width: 120,
          sortable: true,
          fixed: false
        },
        { hide: false, prop: 'created_at', label: '注册时间', width: 140, sortable: true, fixed: false },
        {
          hide: false,
          prop: '_user_status',
          label: '状态',
          width: 80,
          sortable: true,
          fixed: false
        },
        { hide: true, prop: 'updated_at', label: '修改时间', width: 140, sortable: true, fixed: false }
      ],
      // 表格列表数据
      userList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        offset: 0,
        page_size: 15,
        current_page: 1
      },
      // 分页的offset,序号列使用
      offset: 0,
      // 表单结构
      formData: {
        user_roles: [],
        username: null,
        nickname: null,
        job_number: null
      },
      // 表单验证
      rules: {
        mobile: [
          { required: true, message: '输入手机号', trigger: 'blur' },
          { pattern: this.$utils.regular.mobile, message: '手机号格式错误', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '输入账号', trigger: 'blur' },
          { pattern: this.$utils.regular.enNum3to20, message: '用户名格式3-20位英文数字', trigger: 'blur' }
        ],
        // email: [
        //   {required: true, message: '输入邮箱', trigger: 'blur'},
        // ],
        // job_number: [
        //   {required: true, message: '输入工号', trigger: 'blur'},
        // ],
        nickname: [
          { required: true, message: '输入昵称', trigger: 'blur' }
        ]
      },
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '',
        width_height: '480px'
        // mask: false,
        // close_name: '关 闭',
        // confirm_name: '打 印',
      },
      rolesList: [],
      userRoles: [],
      // 抽屉数据
      drawerGroupData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '',
        width_height: '990px'
        // mask: false,
        // close_name: '关 闭',
        // confirm_name: '打 印',
      },
      groupList: [],
      userGroups: [],
      currentUserId: null,
      checkAll: false,
      isIndeterminate: true,
      drawerLoginData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '',
        width_height: '990px',
        show_footer: false
        // mask: false,
        // close_name: '关 闭',
        // confirm_name: '打 印',
      },
      //排序
      orderSort: { 'created_at': 'desc', id: 'desc' },
      customColumnShow: false,
      isColSet: false,
      tableName: 'user',
      passWordDialog: false,
      resetPassword: '',
      copyInfo: '请重试',
      roleDialog: false

    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    '$route.matched': function(n, o) {
      if (n.length === 2) {
        this.init()
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  methods: {
    async init() {
      await this.getUserList()
      this.rolesList = await this.$api.getUserRoles()
    },

    jumpToPage(pageName, params) {
      this.$router.push({
        name: pageName,
        params: params
      })
      // const { href } = this.$router.resolve({
      //   name: pageName,
      //   params:params
      // })
      // window.open(href, '_blank')
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getUserList()

    },
    handleDeptSelect(arr) {
      this.searchCondition.dpt_id = arr ? arr[0] : null
      this.searchButton()
    },
    // 搜索
    searchButton() {
      this.pagingData.current_page = 1
      // this.$refs['apeTable'].handleCurrentChange(1)
      this.getUserList()
    },
    // 响应添加按钮
    async addButton() {
      this.drawerData.loading_text = '玩命加载中……'
      this.drawerData.visible = true
      this.drawerData.title = '添加用户'
      // let list = await this.$api.getUserRoles()
      // this.rolesList = list
      this.$nextTick(() => {
        this.drawerData.loading = false
      })
    },
    // 响应编辑按钮
    async editButton(id, type = 'edit') {
      let { info } = await this.$api.getUserInfo(id)
      this.formData = info
      if (type === 'edit') {
        this.drawerData.loading_text = '玩命加载中……'
        this.drawerData.visible = true
        this.drawerData.title = '编辑用户(UID：' + id + ')'
        // let list = await this.$api.getUserRoles()
        // this.rolesList = list
        this.drawerData.loading = false
      } else if (type === 'password') {
        this.passWordDialog = true
        this.generateRandomPassword()

      } else if (type === 'role') {
        this.roleDialog = true
      }
    },

    // 用户状态修改
    handleStatusChange(row) {
      let text = row.user_status === 1 ? '启用' : '停用'
      this.$confirm('确认要"' + text + '"："' + row.nickname + '"用户吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.changeUserStatus(row.id, row.user_status)
        if (info)
          this.msgSuccess(text + '成功')
      }).catch(function() {
        row.user_status = row.user_status === 0 ? 1 : 0
      })
    },
    // 处理抽屉关闭
    drawerClose() {
      this.initFormData()
      this.drawerData.visible = false
      this.drawerData.loading = true
      // this.rolesList = []
    },
    // 处理抽屉确认
    async drawerConfirm() {
      // 调用组件的数据验证方法
      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    async saveRole() {
      //roleDialog
      let id = await this.$api.editUserRole(this.formData)
      if (id) {
        this.$message.success('保存成功')
        this.roleDialog = false

      }
    },
    // form数据提交，请求接口
    async formSubmit() {
      this.drawerData.loading_text = '玩命提交中……'
      this.drawerData.loading = true
      setTimeout(() => {
        this.drawerData.loading = false
      }, 1000)
      let id = this.formData.id
      if (!this.formData.id) {
        id = await this.$api.saveUser(this.formData)
      } else {
        //仅更新基础信息
        id = await this.$api.updateUser(this.formData)
      }

      this.$nextTick(() => {
        this.drawerData.visible = false
      })
      this.$nextTick(async () => {
        if (id) {
          await this.getUserList()
        }
      })
      this.$nextTick(() => {
        this.msgSuccess('保存成功!')
      })
      this.initFormData()
    },
    // 相应删除按钮
    async deleteButton(id) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.deleteUser(id)
        if (info) await this.getUserList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 初始化数据
    initFormData() {
      // 初始化form表单
      this.$nextTick(() => {
        this.formData = { user_roles: [] }
        this.$refs['userForm'].resetFields()
      })
    },
    sortChange({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getUserList()
    },
    // 初始化用户列表
    async getUserList() {
      this.loadingStaus = true
      // let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let pagingInfo = { page_size: this.pagingData.page_size, current_page: this.pagingData.current_page }
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
      let { list, pages } = await this.$api.getUserList(searchCondition)

      this.$nextTick(() => {
        this.userList = list
      })
      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.pagingData.offset = pages.offset
      this.pagingData.current_page = pages.current_page
      this.pagingData.page_size = pages.page_size
      setTimeout(() => {
        this.loadingStaus = false
      }, 500)
      this.loadingStaus = false
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field] != null && this.searchCondition[field] !== '') {
          condition[field] = this.searchCondition[field]
        }
      })
      if (this.currentField) {
        condition['currentField'] = this.currentField
      }


      return condition
    },
    async userGroupBinding(row) {
      this.drawerGroupData.loading_text = '玩命加载中……'
      this.drawerGroupData.visible = true
      this.currentUserId = row.id
      this.drawerGroupData.title = '分组绑定(UID：' + row.id + ')'
      let { groups_list, user_groups } = await this.$api.getUserGroups(row.id)
      this.groupList = groups_list
      // this.userGroups = user_groups
      user_groups.forEach((item) => {
        this.userGroups.push(Number(item))
      })

      this.$nextTick(() => {
        this.drawerGroupData.loading = false
      })

      // this.$router.push(this.$route.path + '/' + row.id + '/user-groups')
    },
    // 每一个权限组的全选状态
    checkedIndeterminate(val) {
      this.userGroups = val ? this.groupList.map((group) => {
        return group.name
      }) : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.groupList.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.groupList.length
    },
    // 处理点击每个组的全选
    handleCheckAll(v) {
      if (v.checked_status) {
        this.rolePermissions[v.id] = v.children.map((val) => {
          return val.id
        })
      } else {
        this.rolePermissions[v.id] = []
      }
    },

    // 处理抽屉关闭
    drawerGroupClose() {
      this.drawerGroupData.visible = false
      this.drawerGroupData.loading = true
      this.groupList = []
      this.userGroups = []
    },
    // 处理抽屉确认
    async drawerGroupConfirm() {
      this.drawerGroupData.loading_text = '玩命提交中……'
      this.drawerGroupData.loading = true
      let params = {
        user_id: this.currentUserId,
        user_groups: this.userGroups
      }

      await this.$api.saveUserGroups(params)
      this.$nextTick(() => {
        this.drawerGroupData.visible = false
      })
      this.$nextTick(() => {
        this.msgSuccess('保存成功!')
      })
    },

    // 用户登录日志
    async userLoginRecord(row) {
      this.drawerLoginData.loading_text = '玩命加载中……'
      this.drawerLoginData.visible = true
      this.currentUserId = row.id
      this.drawerLoginData.title = '登录日志记录(UID：' + row.id + ')'
      this.$nextTick(() => {
        this.$refs['userLoginRecord'].initList()
        this.drawerLoginData.loading = false
      })
    },
    // 处理抽屉关闭
    drawerLoginRecordClose() {
      this.drawerLoginData.visible = false
      this.drawerLoginData.loading = true
    },

    async exportExcel() {
      try {
        let name = `用户列表`
        let searchCondition = this.handleSearchCondition()
        // 合并
        Object.assign(searchCondition, { order: this.orderSort })
        let response = await this.$api.exportUserList(searchCondition)
        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },

    jumpToDetail(row) {
      this.jumpToPage('UserDetail', { user_id: row.id })
    },
    // eslint-disable-next-line no-unused-vars
    dblclickRow(row, column, event) {
      event.preventDefault()
      if (column.property === 'nickname') {
        this.jumpToDetail(row)
        // this.$notify.info('详情')
      }
    },
    // eslint-disable-next-line no-unused-vars
    addCellClass({ row, column }) {
      if (column && column.property === 'nickname') {
        return 'cell-link'
      }
    },
    showBindDialog(row) {
      // this.$notify.success('显示钉钉账号')
      this.dialogVisible = true
      this.formData = { ...row }
      this.bindForm = { id: this.formData.id, userid: this.formData.userid, reason: null }
    },
    async saveBindDing() {
      this.$confirm('此操作将用户系统账号和钉钉账号绑定, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // this.bindForm = { id: this.formData.id, userid: this.formData.userid }
        await this.$api.bindDingUser(this.bindForm)
        await this.getUserList()
        this.dialogVisible = false
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    generateRandomPassword() {
      let numbers = '0123456789'
      let lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz'
      let uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      let specialCharacters = '!@#$%^&*()_-+=][{}|;:,.<>?'
      let password = ''
      let availableChars = numbers + lowercaseLetters + uppercaseLetters + specialCharacters
      for (let i = 0; i < 12; i++) {
        let randomIndex = Math.floor(Math.random() * availableChars.length)
        password += availableChars[randomIndex]
      }
      this.$set(this.formData, 'password', password)
    },

    copyBtn() {
      this.$confirm('确认要重置:"' + this.formData.nickname + '"用户的密码吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let _t = this
        let id = await this.$api.changePwdUser(this.formData.id, this.formData.password)
        if (id) {
          this.copyInfo = `用户：${this.formData.nickname}\n手机号：${this.formData.mobile}\n重置后的密码为：${this.formData.password}\ntip:为了账户安全,请勤换密码哦~`
          this.$copyText(this.copyInfo).then(function() {
            _t.$message({
              message: '保存/复制成功！',
              type: 'success'
            })
          })
        } else
          _t.$message({
            message: '失败,请联系管理员！',
            type: 'error'
          })
        this.passWordDialog = false
      })
    },
    // eslint-disable-next-line no-unused-vars
    rowClickEvent(row, column, event) {
      // this.$notify.info(`数据ID：${row.id}`)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    }

  },
  mounted() {
    if (this.$route.matched.length === 2) {
      this.init()
    }

  }
}
</script>
<style>
.cell-link {
  /*text-underline-offset: -10px;*/
  /*text-decoration-thickness: 10px;*/
  text-decoration-color: #ff3176;
  text-underline-position: under;
  text-decoration-line: underline;
}

.cell-link:hover {
  cursor: pointer;
}
</style>
